var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-wrap bg-gray" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "我的投保",
          "left-text": "返回",
          "left-arrow": "",
          "z-index": 10,
        },
        on: {
          "click-left": function ($event) {
            return _vm.$router.back(-1)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "vo-pages",
        {
          attrs: {
            data: _vm.list,
            loadedAll: _vm.loadedAll,
            config: { loadedAllMsg: "没有更多了" },
          },
          on: { pullingUp: _vm.pullingUp, pullingDown: _vm.pullingDown },
        },
        [
          _c("div", [
            _c(
              "ul",
              { staticClass: "padding-sm" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "li",
                  {
                    staticClass:
                      "flex  padding-sm solid  margin-bottom-xs bg-white",
                  },
                  [
                    _c("div", { staticClass: "margin-tb-xs margin-right" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: item.goods.cover_image,
                            expression: "item.goods.cover_image",
                          },
                        ],
                        staticClass: "radius-xs",
                        attrs: { width: "70" },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "margin-right" }, [
                      _c(
                        "h2",
                        {
                          staticClass: "text-df margin-top-xs text-black",
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                path: "/goods/" + item.goods_id,
                              })
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.goods.name))]
                      ),
                      _vm._v(" "),
                      _c("ul", { staticClass: "text-sm margin-top-xs" }, [
                        _c("li", { staticClass: "margin-bottom-xxs" }, [
                          _c("span", [_vm._v("投保人姓名：")]),
                          _c("span", [_vm._v(_vm._s(item.policyholder_name))]),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "margin-bottom-xxs" }, [
                          _c("span", [_vm._v("投保人身份证：")]),
                          _c("span", [
                            _vm._v(_vm._s(item.policyholder_identityno)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("li", [_vm._v(_vm._s(item.created_at))]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }