"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Upload = _interopRequireDefault(require("@/components/Upload"));

var _VanFieldSelectDate = _interopRequireDefault(require("@/components/VanFieldSelectDate"));

var _VanFieldPicker = _interopRequireDefault(require("@/components/VanFieldPicker"));

var _EditName = _interopRequireDefault(require("./components/EditName"));

var _VantFieldArea = _interopRequireDefault(require("@/components/Address/VantFieldArea"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Setting',
  components: {
    Upload: _Upload.default,
    EditName: _EditName.default,
    VanFieldSelectDate: _VanFieldSelectDate.default,
    VanFieldPicker: _VanFieldPicker.default,
    VantFieldArea: _VantFieldArea.default
  },
  watch: {},
  data: function data() {
    return {
      text: '1111',
      sex: [{
        id: 0,
        value: '保密'
      }, {
        id: 1,
        value: '男'
      }, {
        id: 2,
        value: '女'
      }],
      marital_status: [{
        id: 0,
        value: '未婚'
      }, {
        id: 1,
        value: '已婚'
      }, {
        id: 2,
        value: '离异'
      }, {
        id: 3,
        value: '丧偶'
      }],
      user: {},
      errorMsg: {}
    };
  },
  filters: {},
  methods: {
    goBack: function goBack() {
      this.$router.go(-1); // 返回上一层
    },
    fetchData: function fetchData() {
      var user = this.userInfo();
      this.sexText = this.sex[user.sex].value;
      this.user = this.userInfo();
    },
    update: function update(form) {
      var _this = this;

      return this.$store.dispatch('user/profile', form).then(function () {
        _this.fetchData();

        _this.$toast.success('更新成功');
      });
    },
    uploadComplete: function uploadComplete(res) {
      var index = res.data.lastIndexOf('.com/');
      this.update({
        'avatar': res.data.substring(index + 5, res.data.length)
      });
    },
    logout: function logout() {
      var _this2 = this;

      this.$store.dispatch('user/logout', {
        $route: this.$route,
        $router: this.$router
      }).then(function () {
        _this2.$router.push({
          path: '/'
        });
      });
    }
  },
  created: function created() {
    this.fetchData();
  }
};
exports.default = _default;