"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _VerifyCodeBtn = _interopRequireDefault(require("@/components/VerifyCodeBtn"));

var _index = require("@/api/user/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      captcha: '',
      user: '',
      type: this.$route.query.type
    };
  },
  computed: {
    disabled: function disabled() {
      if (this.captcha.length === 4) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {},
  components: {
    VerifyCodeBtn: _VerifyCodeBtn.default
  },
  methods: {
    // 发送验证码
    sendVerifyCode: function sendVerifyCode() {},
    cofirm: function cofirm() {
      var _this = this;

      var captcha = this.captcha,
          type = this.type;
      var phone = this.user.phone;
      (0, _index.checkIdentityVerify)({
        captcha: captcha,
        type: type,
        phone: phone
      }).then(function (res) {
        // 验证成功
        if (res.code === 0) {
          _this.$router.push("/my/secure/".concat(type, "?type=").concat(type));
        } else {
          _this.$toast.fail(res.message);
        }
      });
    }
  },
  created: function created() {
    this.user = this.userInfo();
  }
};
exports.default = _default;