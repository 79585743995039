var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user_information" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "资料设置",
          "left-text": "返回",
          "left-arrow": "",
          "z-index": 10,
        },
        on: { "click-left": _vm.goBack },
      }),
      _vm._v(" "),
      _c(
        "van-cell-group",
        { attrs: { title: "基本信息" } },
        [
          _c("van-field", {
            attrs: { label: "头像" },
            scopedSlots: _vm._u([
              {
                key: "input",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-right response" },
                      [
                        _c("upload", {
                          attrs: {
                            url: _vm.user.avatar,
                            custClass: "avartarUpload",
                          },
                          on: { uploadComplete: _vm.uploadComplete },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("edit-name", {
            attrs: { label: "昵称", field: "name", "input-align": "right" },
            on: { save: _vm.update },
            model: {
              value: _vm.user.name,
              callback: function ($$v) {
                _vm.$set(_vm.user, "name", $$v)
              },
              expression: "user.name",
            },
          }),
          _vm._v(" "),
          _c("van-field", {
            attrs: {
              name: "switch",
              label: "我的手机号",
              value: _vm.mobileStar(_vm.user.phone),
              readonly: "",
              "input-align": "right",
            },
          }),
          _vm._v(" "),
          _c("van-field", {
            attrs: {
              name: "switch",
              label: "工号",
              value: _vm.user.job_no,
              "input-align": "right",
              readonly: "",
            },
          }),
          _vm._v(" "),
          _c("van-field", {
            attrs: {
              label: "身份证号",
              value: _vm.user.identity_no,
              "input-align": "right",
              readonly: "",
            },
          }),
          _vm._v(" "),
          _c("edit-name", {
            attrs: {
              label: "真实姓名",
              field: "real_name",
              "input-align": "right",
              required: false,
            },
            on: { save: _vm.update },
            model: {
              value: _vm.user.real_name,
              callback: function ($$v) {
                _vm.$set(_vm.user, "real_name", $$v)
              },
              expression: "user.real_name",
            },
          }),
          _vm._v(" "),
          _c("van-field-picker", {
            attrs: {
              columns: _vm.sex,
              "selected-id": _vm.user.sex,
              label: "性别",
              "input-align": "right",
              field: "sex",
            },
            on: { confirm: _vm.update },
            model: {
              value: _vm.user.sex,
              callback: function ($$v) {
                _vm.$set(_vm.user, "sex", $$v)
              },
              expression: "user.sex",
            },
          }),
          _vm._v(" "),
          _c("van-field-select-date", {
            attrs: { label: "生日", field: "birthday", "input-align": "right" },
            on: { confirm: _vm.update },
            model: {
              value: _vm.user.birthday,
              callback: function ($$v) {
                _vm.$set(_vm.user, "birthday", $$v)
              },
              expression: "user.birthday",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.user
        ? _c(
            "van-button",
            {
              staticClass: "logoutBtn",
              attrs: { block: "", round: "" },
              on: { click: _vm.logout },
            },
            [_vm._v("退出登录")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }