var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mine" } },
    [
      _c(
        "van-cell-group",
        { staticClass: "order-container order-container-first" },
        [
          _vm.user
            ? _c("van-icon", {
                staticClass: "user_set",
                attrs: { name: "setting-o", size: "1.8em" },
                on: {
                  click: function ($event) {
                    return _vm.goToPage("/setting")
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "van-cell",
            { attrs: { "label-class": "labelClass", center: "" } },
            [
              _c("template", { slot: "title" }, [
                _vm.user
                  ? _c(
                      "div",
                      { staticClass: "personMsg" },
                      [
                        _vm.user.avatar
                          ? [
                              _c("img", {
                                staticClass: "iconImage",
                                attrs: {
                                  src: _vm.user
                                    ? _vm.resizeImage(_vm.user.avatar, 500, 500)
                                    : _vm.icon.login_icon,
                                },
                                on: { click: _vm.priview },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.user
                          ? _c("div", { staticClass: "personInfo" }, [
                              _c("b", { staticClass: "text-lg" }, [
                                _vm._v(_vm._s(_vm.user.name)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "margin-top-xs" }, [
                                _vm._v("工号：" + _vm._s(_vm.user.job_no)),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.user
                  ? _c("div", { staticClass: "personMsg" }, [
                      _c("img", {
                        staticClass: "iconImage",
                        attrs: { src: _vm.icon.noLogin_icon, alt: "" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "personInfo" }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.goToPage("login")
                              },
                            },
                          },
                          [_vm._v("立即登录")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "van-cell-group",
        { staticClass: "order-container" },
        [
          _c("van-cell", {
            attrs: {
              title: "我的投保",
              "title-class": "margin-left-sm",
              icon: "gold-coin",
              "is-link": "",
              color: "#fff",
              value: _vm.stats ? _vm.stats.insure_total : "",
            },
            on: {
              click: function ($event) {
                return _vm.$router.push("/user/insure")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("van-icon", {
                      attrs: {
                        "class-prefix": "iconfont",
                        name: "toubaojieguo",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("van-cell", {
            attrs: {
              title: "我的业绩",
              "title-class": "margin-left-sm",
              icon: "gold-coin",
              "is-link": "",
              color: "#fff",
            },
            on: {
              click: function ($event) {
                return _vm.$router.push({ name: "userPerformance" })
              },
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("van-icon", {
                      attrs: { "class-prefix": "iconfont", name: "yeji" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("van-cell", {
            attrs: {
              title: "产品费用率",
              "title-class": "margin-left-sm",
              icon: "gold-coin",
              "is-link": "",
              color: "#fff",
            },
            on: {
              click: function ($event) {
                return _vm.$router.push({ name: "userCompanyProductPdf" })
              },
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("van-icon", {
                      attrs: { "class-prefix": "iconfont", name: "chanpin" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("van-cell", {
            attrs: {
              title: "我的收入",
              "title-class": "margin-left-sm",
              icon: "gold-coin",
              "is-link": "",
              color: "#fff",
            },
            on: {
              click: function ($event) {
                return _vm.$router.push("/user/income")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("van-icon", {
                      attrs: { "class-prefix": "iconfont", name: "shouru" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "van-cell-group",
        { staticClass: "order-container" },
        [
          _c("van-cell", {
            attrs: {
              title: "账号与安全",
              "title-class": "margin-left-sm",
              icon: "gold-coin",
              "is-link": "",
              color: "#fff",
              value: "修改 手机/密码",
            },
            on: {
              click: function ($event) {
                return _vm.$router.push("/my/secure")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("van-icon", {
                      attrs: { "class-prefix": "iconfont", name: "anquan" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("van-cell", {
            attrs: {
              title: "个人信息",
              "title-class": "margin-left-sm",
              "is-link": "",
            },
            on: {
              click: function ($event) {
                return _vm.$router.push("/setting")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("van-icon", {
                      attrs: { "class-prefix": "iconfont", name: "gerenxinxi" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "van-cell-group",
        { staticClass: "order-container" },
        [
          _c("van-cell", {
            attrs: {
              title: "联系客服",
              "title-class": "margin-left-sm",
              "is-link": "",
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("van-icon", {
                      attrs: { "class-prefix": "iconfont", name: "dianhua" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("van-cell", {
            attrs: {
              title: "意见反馈",
              "is-link": "",
              "title-class": "margin-left-sm",
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("van-icon", {
                      attrs: { "class-prefix": "iconfont", name: "yijian" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center text-gray text-sm margin-top-xxl" },
        [_vm._v("【恒生顾销】")]
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "2rem" } }),
      _vm._v(" "),
      _c("footer-tabbar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }